@media (max-width: 768px) {
  footer {
    visibility: hidden !important;
    display: none !important;
  }

  header {
    visibility: hidden;
    display: none;
  }

  .background.img {
    background-image: url("static/images/bg.png") !important;
  }
}


footer a {
  color: white;
}

footer a::hover {
  color: lightgray;
}

footer .image {
  content: url("static/images/logo_light.svg");
}

footer .imageDark {
  content: url("static/images/ds.svg");
}

.grid>.column {
  padding: 0 2.4rem 2.4rem 2.4rem;
}