/*=====================================================
Important! 
/js/svg-icons.js is required.
======================================================= */

/*=====================================================
Font Awesome 4.4 as SVG Icons via http://fontastic.me  
class = .fa- (Go to https://fortawesome.github.io/Font-Awesome/icons/)

SVG ICONS - Don't use icon fonts: 
https://speakerdeck.com/ninjanails/death-to-fa-fonts
======================================================= */

svg {
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;

}
[class*="fa-"].large{font-size: 8rem;}


.fa-glass {
	width: 1.0000000298023224em;
}

.fa-music {
	width: 0.8571396097540855em;
}

.fa-search {
	width: 0.9285706554849185em;
}

.fa-envelope-o {
	width: 1.000000972300768em;
}

.fa-heart {
	width: 0.9999999646097422em;
}

.fa-star {
	width: 0.9285713043063879em;
}

.fa-star-o {
	width: 0.9285713043063879em;
}

.fa-user {
	width: 0.785713868836563em;
}

.fa-film {
	width: 1.071429569274187em;
}

.fa-th-large {
	width: 0.9285712415972966em;
}

.fa-th {
	width: 1.0000000521540642em;
}

.fa-th-list {
	width: 1.0000000521540642em;
}

.fa-check {
	width: 1.0000000596046448em;
}

.fa-close {
	width: 0.7857149094343185em;
}

.fa-search-plus {
	width: 0.9285706554849185em;
}

.fa-search-minus {
	width: 0.9285706554849185em;
}

.fa-power-off {
	width: 0.8571455205480252em;
}

.fa-signal {
	width: 0.9999999916180968em;
}

.fa-trash-o {
	width: 0.7857138803228736em;
}

.fa-home {
	width: 0.928572153672576em;
}

.fa-file-o {
	width: 0.8571445867419243em;
}

.fa-clock-o {
	width: 0.8571425850192682em;
}

.fa-road {
	width: 1.0714228972792625em;
}

.fa-download {
	width: 0.9285713359713554em;
}

.fa-arrow-circle-o-down {
	width: 0.8571425850192682em;
}

.fa-arrow-circle-o-up {
	width: 0.8571425850192682em;
}

.fa-inbox {
	width: 0.8571455143392086em;
}

.fa-play-circle-o {
	width: 0.8571425850192682em;
}

.fa-repeat {
	width: 0.8571445668737852em;
}

.fa-refresh {
	width: 0.8571436268589423em;
}

.fa-list-alt {
	width: 1.000000972300768em;
}

.fa-lock {
	width: 1.000000972300768em;
}

.fa-flag {
	width: 1.0357158780097961em;
}

.fa-headphones {
	width: 0.9285684004426003em;
}

.fa-volume-off {
	width: 0.42857232317328453em;
}

.fa-volume-down {
	width: 0.642858412116766em;
}

.fa-volume-up {
	width: 0.9285729192197323em;
}

.fa-qrcode {
	width: 0.7857138514518738em;
}

.fa-tag {
	width: 0.8454238213598728em;
}

.fa-tags {
	width: 1.0597098506987095em;
}

.fa-book {
	width: 0.9302451089024544em;
}

.fa-bookmark {
	width: 0.7142852507531643em;
}

.fa-print {
	width: 0.9285733308643103em;
}

.fa-camera {
	width: 1.0714288031060732em;
}

.fa-font {
	width: 0.9285733103752136em;
}

.fa-bold {
	width: 0.7857098877429962em;
}

.fa-italic {
	width: 0.5714278221130371em;
}

.fa-text-height {
	width: 1.000002734363079em;
}

.fa-text-width {
	width: 0.857142724096775em;
}

.fa-align-left {
	width: 0.9999999664723873em;
}

.fa-align-center {
	width: 1.0000009797513485em;
}

.fa-align-right {
	width: 1.0000009797513485em;
}

.fa-align-justify {
	width: 1.0000009797513485em;
}

.fa-list {
	width: 0.9999999906867743em;
}

.fa-dedent {
	width: 0.9999989476054907em;
}

.fa-indent {
	width: 0.9999989476054907em;
}

.fa-video-camera {
	width: 0.9999980057277753em;
}

.fa-image {
	width: 1.071429569274187em;
}

.fa-pencil {
	width: 0.8454248607158661em;
}

.fa-map-marker {
	width: 0.8714316554367542em;
	margin-top: -0.2em;
}

.fa-adjust {
	width: 0.8571425850192682em;
}

.fa-tint {
	width: 0.8714316815137863em;
}

.fa-edit {
	width: 0.9955342138805463em;
}

.fa-share-square-o {
	width: 0.9285693491497113em;
}

.fa-check-square-o {
	width: 0.9280096615352704em;
}

.fa-arrows {
	width: 0.9999999888241291em;
}

.fa-step-backward {
	width: 0.5714307241141796em;
}

.fa-fast-backward {
	width: 1.000000935047865em;
}

.fa-backward {
	width: 0.9966524690389633em;
}

.fa-play {
	width: 0.7851562518626451em;
}

.fa-pause {
	width: 0.8571436069905758em;
}

.fa-stop {
	width: 0.8571436069905758em;
}

.fa-forward {
	width: 0.8604901600629091em;
}

.fa-fast-forward {
	width: 0.9999990034848452em;
}

.fa-step-forward {
	width: 0.5714267063885927em;
}

.fa-eject {
	width: 0.85825614631176em;
}

.fa-chevron-left {
	width: 0.7500009983778em;
}

.fa-chevron-right {
	width: 0.6785712540149689em;
}

.fa-plus-circle {
	width: 0.8571425850192682em;
}

.fa-minus-circle {
	width: 0.8571425850192682em;
}

.fa-times-circle {
	width: 0.8571425850192682em;
}

.fa-check-circle {
	width: 0.8571425850192682em;
}

.fa-question-circle {
	width: 0.8571425850192682em;
}

.fa-info-circle {
	width: 0.8571425850192682em;
}

.fa-crosshairs {
	width: 0.8571436069905758em;
}

.fa-times-circle-o {
	width: 0.8571425850192682em;
}

.fa-check-circle-o {
	width: 0.8571425850192682em;
}

.fa-ban {
	width: 0.8571426247557004em;
}

.fa-arrow-left {
	width: 0.8928571492433548em;
}

.fa-arrow-right {
	width: 0.8214280630151052em;
}

.fa-arrow-up {
	width: 0.9285692870616913em;
}

.fa-arrow-down {
	width: 0.9285712018609047em;
}

.fa-mail-forward {
	width: 1.0000031888484955em;
}

.fa-expand {
	width: 0.8571445606648922em;
}

.fa-compress {
	width: 0.8571445886045694em;
}

.fa-plus {
	width: 0.785714827477932em;
}

.fa-minus {
	width: 0.7857138440012932em;
}

.fa-asterisk {
	width: 0.9285713285207748em;
}

.fa-exclamation-circle {
	width: 0.8571425850192682em;
}

.fa-leaf {
	width: 0.9999980080174282em;
}

.fa-fire {
	width: 0.7857128586620092em;
}

.fa-eye {
	width: 0.9999980051070452em;
}

.fa-eye-slash {
	width: 1.000001983717084em;
}

.fa-exclamation-triangle {
	width: 0.9999999636784196em;
}

.fa-plane {
	width: 0.7860879210056737em;
}

.fa-calendar {
	width: 0.9285707051554937em;
}

.fa-random {
	width: 0.9999999022111297em;
}

.fa-comment {
	width: 0.9999974196152834em;
}

.fa-magnet {
	width: 0.8571425850192682em;
}

.fa-chevron-up {
	width: 0.9999999701976776em;
}

.fa-chevron-down {
	width: 0.9999999850988388em;
}

.fa-retweet {
	width: 1.0714306645095348em;
}

.fa-shopping-cart {
	width: 0.9285732470452785em;
}

.fa-folder {
	width: 0.9285712813336886em;
}

.fa-folder-open {
	width: 1.0485479356721044em;
}

.fa-arrows-v {
	width: 0.42857228219509125em;
}

.fa-arrows-h {
	width: 1.000000972300768em;
}

.fa-bar-chart {
	width: 1.1428574323654175em;
}



.fa-camera-retro {
	width: 1.0000000074505806em;
}

.fa-key {
	width: 0.9391738375027963em;
}

.fa-cogs {
	width: 1.0714287403970957em;
}

.fa-comments {
	width: 1.0000034073991628em;
}

.fa-thumbs-o-up {
	width: 0.8571465983986855em;
}

.fa-thumbs-o-down {
	width: 0.8571435585618019em;
}

.fa-star-half {
	width: 0.4642870929092169em;
}

.fa-heart-o {
	width: 1.0000009778887033em;
}

.fa-sign-out {
	width: 0.875000032285854em;
}

.fa-linkedin-square {
	width: 0.8571416462459638em;
}

.fa-thumb-tack {
	width: 0.6428600810468197em;
}

.fa-external-link {
	width: 0.9999981845417096em;
}

.fa-sign-in {
	width: 0.8571436069905758em;
}

.fa-trophy {
	width: 0.9285706554849185em;
}

.fa-upload {
	width: 0.9285723492503166em;
}

.fa-lemon-o {
	width: 0.8565869312733412em;
}

.fa-phone {
	width: 0.7857128540053964em;
}

.fa-square-o {
	width: 0.7857118571796491em;
}

.fa-bookmark-o {
	width: 0.7142852507531643em;
}

.fa-phone-square {
	width: 0.8571416462459638em;
}

.fa-unlock {
	width: 1.000000972300768em;
}

.fa-credit-card {
	width: 1.0714279003441334em;
}

.fa-feed {
	width: 0.7864560410380363em;
}

.fa-hdd-o {
	width: 0.8571435995399952em;
}

.fa-bullhorn {
	width: 1.000001672655344em;
}

.fa-bell-o {
	width: 0.999999426305294em;
}

.fa-certificate {
	width: 0.8571425257250667em;
}

.fa-hand-o-right {
	width: 1.0000000074505806em;
}

.fa-hand-o-left {
	width: 0.9999989792704582em;
}

.fa-hand-o-up {
	width: 0.8571425552167966em;
}

.fa-hand-o-down {
	width: 0.8571416288614273em;
}

.fa-arrow-circle-left {
	width: 0.8571425850192682em;
}

.fa-arrow-circle-right {
	width: 0.8571425850192682em;
}

.fa-arrow-circle-up {
	width: 0.8571425850192682em;
}

.fa-arrow-circle-down {
	width: 0.8571425850192682em;
}

.fa-wrench {
	width: 0.9391775503754616em;
}

.fa-tasks {
	width: 1.0000009797513485em;
}

.fa-filter {
	width: 0.7857138165272772em;
}

.fa-arrows-alt {
	width: 0.857147540897131em;
}

.fa-group {
	width: 1.071428755919138em;
}

.fa-chain {
	width: 0.9285723119974136em;
}

.fa-cloud {
	width: 1.0714285423358234em;
}

.fa-flask {
	width: 0.9285696372389793em;
}

.fa-cut {
	width: 0.9975810423493385em;
}

.fa-copy {
	width: 1.0000010058283806em;
}

.fa-paperclip {
	width: 0.7857148349285126em;
}

.fa-floppy-o {
	width: 0.8571445867419243em;
}

.fa-square {
	width: 0.8571416462459638em;
}

.fa-bars {
	width: 0.8571436069905758em;
}

.fa-list-ul {
	width: 1.0000010132789612em;
}

.fa-list-ol {
	width: 1.0083701331168413em;
}

.fa-strikethrough {
	width: 0.9999989485368133em;
}

.fa-underline {
	width: 0.8571445560082793em;
}

.fa-table {
	width: 0.9285723157227039em;
}

.fa-magic {
	width: 0.9436386581510305em;
}

.fa-truck {
	width: 1.0357128381729126em;
}

.fa-pinterest {
	width: 0.8571391701698303em;
}

.fa-pinterest-square {
	width: 0.8571406031646802em;
}

.fa-google-plus-square {
	width: 0.8571416462459638em;
}

.fa-google-plus {
	width: 1.2857149516544268em;
}

.fa-money {
	width: 1.0714295767247677em;
}

.fa-caret-down {
	width: 0.571429718285799em;
}

.fa-caret-up {
	width: 0.5714296959340572em;
}

.fa-caret-left {
	width: 0.3928574174642563em;
}

.fa-caret-right {
	width: 0.32142970090126255em;
}

.fa-columns {
	width: 0.9285723157227039em;
}

.fa-sort {
	width: 0.5714296959340572em;
}

.fa-sort-desc {
	width: 0.571429718285799em;
}

.fa-sort-asc {
	width: 0.5714296959340572em;
}

.fa-envelope {
	width: 1.0000022239983082em;
}

.fa-linkedin {
	width: 0.8571429451305903em;
}

.fa-rotate-left {
	width: 0.8571445606648922em;
}

.fa-gavel {
	width: 1.0106014609336853em;
}

.fa-dashboard {
	width: 1.000001922249794em;
}

.fa-comment-o {
	width: 0.9999974196152834em;
}

.fa-comments-o {
	width: 1.0000035266084524em;
}

.fa-bolt {
	width: 1.000000972300768em;
}

.fa-sitemap {
	width: 1.0000010058283806em;
}

.fa-umbrella {
	width: 0.9285703394562006em;
}

.fa-clipboard {
	width: 1.000001959502697em;
}

.fa-lightbulb-o {
	width: 0.5714296922087669em;
}

.fa-exchange {
	width: 0.9999989476054907em;
}

.fa-cloud-download {
	width: 1.0714285423358234em;
}

.fa-cloud-upload {
	width: 1.0714285423358234em;
}

.fa-user-md {
	width: 0.785713868836563em;
}

.fa-stethoscope {
	width: 0.7857138092319147em;
}

.fa-suitcase {
	width: 1.000000116725687em;
}

.fa-bell {
	width: 0.999999426305294em;
}

.fa-coffee {
	width: 1.0357122694451846em;
}

.fa-cutlery {
	width: 0.7857128530740738em;
}

.fa-file-text-o {
	width: 0.8571445867419243em;
}

.fa-building-o {
	width: 1.000000116725687em;
}

.fa-hospital-o {
	width: 1.000000116725687em;
}

.fa-ambulance {
	width: 1.1071423441171646em;
}

.fa-medkit {
	width: 1.000000116725687em;
}

.fa-fighter-jet {
	width: 1.0714295953512192em;
}

.fa-beer {
	width: 0.9642851203680038em;
}

.fa-h-square {
	width: 0.8571416462459638em;
}

.fa-plus-square {
	width: 0.8571416462459638em;
}

.fa-angle-double-left {
	width: 0.5892861261963844em;
}

.fa-angle-double-right {
	width: 0.5535723362118006em;
}

.fa-angle-double-up {
	width: 0.642861433327198em;
}

.fa-angle-double-down {
	width: 0.6428574100136757em;
}

.fa-angle-left {
	width: 0.3750009909272194em;
}

.fa-angle-right {
	width: 0.3392861280590296em;
}

.fa-angle-up {
	width: 0.642861433327198em;
}

.fa-angle-down {
	width: 0.6428574100136757em;
}

.fa-desktop {
	width: 1.071435708552599em;
}

.fa-laptop {
	width: 1.071429569274187em;
}

.fa-tablet {
	width: 1.071429569274187em;
}

.fa-mobile {
	width: 1.071429569274187em;
}

.fa-circle-o {
	width: 0.8571425850192682em;
}

.fa-quote-left {
	width: 0.9285713583230972em;
}

.fa-quote-right {
	width: 0.9285713285207748em;
}

.fa-spinner {
	width: 1.0000000596046448em;
}

.fa-circle {
	width: 0.8571425850192682em;
}

.fa-mail-reply {
	width: 1.0000029690563679em;
}

.fa-folder-o {
	width: 0.9285712813336886em;
}

.fa-folder-open-o {
	width: 1.0652896141011752em;
}

.fa-smile-o {
	width: 0.8571425850192682em;
}

.fa-frown-o {
	width: 0.8571425850192682em;
}

.fa-meh-o {
	width: 0.8571425850192682em;
}

.fa-gamepad {
	width: 1.0714295854171496em;
}

.fa-keyboard-o {
	width: 1.0714286640286446em;
}

.fa-flag-o {
	width: 1.0357160121202469em;
}

.fa-flag-checkered {
	width: 1.0357160121202469em;
}

.fa-terminal {
	width: 0.9358262214809656em;
}

.fa-code {
	width: 1.0714276805520058em;
}

.fa-mail-reply-all {
	width: 0.9999999292194843em;
}

.fa-star-half-empty {
	width: 0.9285682821646333em;
}

.fa-location-arrow {
	width: 0.7859947793185711em;
}

.fa-crop {
	width: 0.9285703515633941em;
}

.fa-code-fork {
	width: 0.5714296698570251em;
}

.fa-chain-broken {
	width: 0.9285713344191509em;
}

.fa-question {
	width: 0.6221200153231621em;
}

.fa-info {
	width: 0.857143547385931em;
}

.fa-exclamation {
	width: 0.857143547385931em
}

.fa-superscript {
	width: 0.8588164150714874em;
}

.fa-subscript {
	width: 0.8599326312541962em;
}

.fa-eraser {
	width: 1.071428793715313em;
}

.fa-puzzle-piece {
	width: 0.9285733483266085em;
}

.fa-microphone {
	width: 0.6428574770689011em;
}

.fa-microphone-slash {
	width: 0.7857128698378801em;
}

.fa-shield {
	width: 0.7142831720411777em;
}

.fa-calendar-o {
	width: 0.9285707051554937em;
}

.fa-fire-extinguisher {
	width: 0.7845058117527515em;
}

.fa-rocket {
	width: 0.9441959485411644em;
}

.fa-maxcdn {
	width: 0.9815869629383087em;
}

.fa-chevron-circle-left {
	width: 0.8571425850192682em;
}

.fa-chevron-circle-right {
	width: 0.8571425850192682em;
}

.fa-chevron-circle-up {
	width: 0.8571425850192682em;
}

.fa-chevron-circle-down {
	width: 0.8571425850192682em;
}

.fa-html5 {
	width: 0.7857138514518738em;
}

.fa-css3 {
	width: 1.0000000596046448em;
}

.fa-anchor {
	width: 0.9999980125576258em;
}

.fa-unlock-alt {
	width: 1.000000972300768em;
}

.fa-bullseye {
	width: 0.8571425850192682em;
}

.fa-ellipsis-h {
	width: 0.7857148125767708em;
}

.fa-ellipsis-v {
	width: 0.21428712457418442em;
}

.fa-rss-square {
	width: 0.8571416462459638em;
}

.fa-play-circle {
	width: 0.8571425850192682em;
}

.fa-ticket {
	width: 1.0005576871335506em;
}

.fa-minus-square {
	width: 0.8571416462459638em;
}

.fa-minus-square-o {
	width: 0.7857118571796491em;
}

.fa-level-up {
	width: 0.5734755680896342em;
}

.fa-level-down {
	width: 0.5731032819021493em;
}

.fa-check-square {
	width: 0.8571416462459638em;
}

.fa-pencil-square {
	width: 0.8571416462459638em;
}

.fa-external-link-square {
	width: 0.8571416462459638em;
}

.fa-share-square {
	width: 0.8571416462459638em;
}

.fa-caret-square-o-down {
	width: 0.8571416462459638em;
}

.fa-caret-square-o-up {
	width: 0.8571416462459638em;
}

.fa-caret-square-o-right {
	width: 0.8571416462459638em;
}

.fa-eur {
	width: 0.565288751386106em;
}

.fa-gbp {
	width: 0.5691953515633941em;
}

.fa-dollar {
	width: 0.5691973492503166em;
}

.fa-inr {
	width: 0.5011180695146322em;
}

.fa-cny {
	width: 0.5731013106415048em;
}

.fa-rouble {
	width: 0.7142853057011962em;
}

.fa-krw {
	width: 1.0000009899958968em;
}

.fa-bitcoin {
	width: 0.736978217959404em;
}

.fa-file {
	width: 0.8571426197886467em;
}

.fa-file-text {
	width: 0.8571426197886467em;
}

.fa-sort-alpha-asc {
	width: 0.9428952634334564em;
}

.fa-sort-alpha-desc {
	width: 0.9428952634334564em;
}

.fa-sort-amount-asc {
	width: 1.0159967839717865em;
}

.fa-sort-amount-desc {
	width: 1.0159967839717865em;
}

.fa-sort-numeric-asc {
	width: 0.8452380001544952em;
}

.fa-sort-numeric-desc {
	width: 0.8452380001544952em;
}

.fa-thumbs-up {
	width: 0.8928591571748257em;
}

.fa-thumbs-down {
	width: 0.8928584419190884em;
}

.fa-youtube-square {
	width: 0.8571416462459638em;
}

.fa-youtube {
	width: 0.8571435324847698em;
}

.fa-xing {
	width: 0.786087267100811em;
}

.fa-xing-square {
	width: 0.8571416462459638em;
}

.fa-youtube-play {
	width: 1.0000000305008143em;
}

.fa-stack-overflow {
	width: 0.8571415841579437em;
}

.fa-instagram {
	width: 0.8571416462459638em;
}

.fa-flickr {
	width: 0.8571436131993941em;
}

.fa-adn {
	width: 0.8571425850192682em;
}

.fa-bitbucket {
	width: 0.78617824614048em;
}

.fa-bitbucket-square {
	width: 0.8571416462459638em;
}

.fa-tumblr {
	width: 0.6093739867210388em;
}

.fa-tumblr-square {
	width: 0.8571416462459638em;
}

.fa-long-arrow-down {
	width: 0.42857166891917586em;
}

.fa-long-arrow-up {
	width: 0.4285729229450226em;
}

.fa-long-arrow-left {
	width: 1.0357138589024544em;
}

.fa-long-arrow-right {
	width: 0.9642862295731902em;
}

.fa-female {
	width: 0.7142941057682037em;
}

.fa-male {
	width: 0.5714317113161087em;
}

.fa-gittip {
	width: 0.8571425850192682em;
}

.fa-sun-o {
	width: 1.0000000223517418em;
}

.fa-moon-o {
	width: 0.8213352287809048em;
}

.fa-archive {
	width: 1.0000020563602448em;
}

.fa-bug {
	width: 0.9285741709172726em;
}

.fa-vk {
	width: 1.0777527708560228em;
}

.fa-weibo {
	width: 1.0041852121551074em;
}

.fa-renren {
	width: 0.8571416437625885em;
}

.fa-pagelines {
	width: 0.7823682955154254em;
}

.fa-stack-exchange {
	width: 0.7142871618270874em;
}

.fa-arrow-circle-o-right {
	width: 0.8571425850192682em;
}

.fa-arrow-circle-o-left {
	width: 0.8571425850192682em;
}

.fa-caret-square-o-left {
	width: 0.8571416462459638em;
}

.fa-dot-circle-o {
	width: 0.8571425850192682em;
}

.fa-wheelchair {
	width: 0.9090400412678719em;
}

.fa-vimeo-square {
	width: 0.8571416462459638em;
}

.fa-try {
	width: 0.6428615320473909em;
}

.fa-plus-square-o {
	width: 0.7857118571796491em;
}

.fa-space-shuttle {
	width: 1.214285247027874em;
}

.fa-slack {
	width: 0.9285693690180779em;
}

.fa-envelope-square {
	width: 0.8571436131993941em;
}

.fa-wordpress {
	width: 1.0000000049670539em;
}

.fa-openid {
	width: 0.9999983335533216em;
}

.fa-bank {
	width: 1.0714267492294312em;
}

.fa-graduation-cap {
	width: 1.2857187371701002em;
}

.fa-yahoo {
	width: 0.8571445047855377em;
}

.fa-google {
	width: 0.8398430943489075em;
}

.fa-reddit {
	width: 1.0000000049670539em;
}

.fa-reddit-square {
	width: 0.8571416462459638em;
}

.fa-stumbleupon-circle {
	width: 0.8571425850192682em;
}

.fa-stumbleupon {
	width: 1.0714269926149882em;
}

.fa-delicious {
	width: 0.8571416462459638em;
}

.fa-digg {
	width: 1.1428574323654175em;
}

.fa-pied-piper {
	width: 0.8571416462459638em;
}

.fa-pied-piper-alt {
	width: 1.1372744292020798em;
}

.fa-drupal {
	width: 0.8571418623128011em;
}

.fa-joomla {
	width: 0.8571426148215924em;
}

.fa-language {
	width: 0.8571416030172259em;
}

.fa-fax {
	width: 1.0000000037252903em;
}

.fa-building {
	width: 0.7857138179242611em;
}

.fa-child {
	width: 0.7142872214317322em;
}

.fa-paw {
	width: 0.9285706828037519em;
}

.fa-spoon {
	width: 1.0000000298023224em;
}

.fa-cube {
	width: 0.9285703506320715em;
}

.fa-cubes {
	width: 1.2142920158803463em;
}

.fa-behance {
	width: 1.1428583860397339em;
}

.fa-behance-square {
	width: 0.8571436131993941em;
}

.fa-steam {
	width: 0.9999989867210388em;
}

.fa-steam-square {
	width: 0.8571436131992414em;
}

.fa-recycle {
	width: 1.0012093782424927em;
}

.fa-automobile {
	width: 1.1428605308756232em;
}

.fa-cab {
	width: 1.1428585043177009em;
}

.fa-tree {
	width: 0.8571485541760921em;
}

.fa-spotify {
	width: 0.8571425850192682em;
}

.fa-deviantart {
	width: 0.5714287161827087em;
}

.fa-soundcloud {
	width: 1.2857149858027697em;
}

.fa-database {
	width: 0.8571426197886467em;
}

.fa-file-pdf-o {
	width: 0.8571445867419243em;
}

.fa-file-word-o {
	width: 0.8571445867419243em;
}

.fa-file-excel-o {
	width: 0.8571445867419243em;
}

.fa-file-powerpoint-o {
	width: 0.8571445867419243em;
}

.fa-file-image-o {
	width: 0.8571445867419243em;
}

.fa-file-archive-o {
	width: 0.8571445867419243em;
}

.fa-file-audio-o {
	width: 0.8571445867419243em;
}

.fa-file-movie-o {
	width: 0.8571445867419243em;
}

.fa-file-code-o {
	width: 0.8571445867419243em;
}

.fa-vine {
	width: 0.857145681977272em;
}

.fa-codepen {
	width: 1.0000000298023224em;
}

.fa-jsfiddle {
	width: 1.1428555250167847em;
}

.fa-life-bouy {
	width: 1.0000000049670539em;
}

.fa-circle-o-notch {
	width: 0.9999980702996254em;
}

.fa-ra {
	width: 0.999438002705574em;
}

.fa-empire {
	width: 1.0000000049670539em;
}

.fa-git-square {
	width: 0.8571416462459638em;
}

.fa-git {
	width: 1.0000000447034836em;
}

.fa-hacker-news {
	width: 0.8571416462459638em;
}

.fa-tencent-weibo {
	width: 0.7142878770828247em;
}

.fa-qq {
	width: 1.0000018551945686em;
}

.fa-wechat {
	width: 1.142860472202301em;
}

.fa-paper-plane {
	width: 1.0009309110464528em;
}

.fa-paper-plane-o {
	width: 1.0005583113525063em;
}

.fa-history {
	width: 0.8571445606648922em;
}

.fa-circle-thin {
	width: 0.8571425850192682em;
}

.fa-header {
	width: 1.0000018998980522em;
}

.fa-paragraph {
	width: 0.7265684753656387em;
}

.fa-sliders {
	width: 0.8571425676345825em;
}

.fa-share-alt {
	width: 0.8571425974369049em;
}

.fa-share-alt-square {
	width: 0.8571416462459638em;
}

.fa-bomb {
	width: 1.0000009909272194em;
}

.fa-futbol-o {
	width: 1.0000000049670539em;
}

.fa-tty {
	width: 1.0000020181760192em;
}

.fa-binoculars {
	width: 1.0000019930303097em;
}

.fa-plug {
	width: 0.9999989569187164em;
}

.fa-slideshare {
	width: 1.000006040558219em;
}

.fa-twitch {
	width: 0.9999990165233612em;
}

.fa-yelp {
	width: 0.8580739721655846em;
}

.fa-newspaper-o {
	width: 1.1428594440221786em;
}

.fa-wifi {
	width: 1.142857201397419em;
}

.fa-calculator {
	width: 0.9285713012019414em;
}

.fa-google-wallet {
	width: 0.9977688640356064em;
}

.fa-cc-visa {
	width: 1.2857130939760282em;
}

.fa-cc-mastercard {
	width: 1.2857130939760282em;
}

.fa-cc-discover {
	width: 1.2857129871845245em;
}

.fa-cc-amex {
	width: 1.285718947649002em;
}

.fa-cc-paypal {
	width: 1.2857130939760282em;
}

.fa-cc-stripe {
	width: 1.2857130939760282em;
}

.fa-bell-slash {
	width: 1.1428565569221973em;
}

.fa-bell-slash-o {
	width: 1.1428565569221973em;
}

.fa-trash {
	width: 0.7857138803228736em;
}

.fa-copyright {
	width: 0.8571425850192682em;
}

.fa-at {
	width: 0.8571425999204312em;
}

.fa-eyedropper {
	width: 1.0000010132789612em;
}

.fa-paint-brush {
	width: 0.9988838285207748em;
}

.fa-birthday-cake {
	width: 0.9999999776482582em;
}

.fa-area-chart {
	width: 1.1428574323654175em;
}

.fa-pie-chart {
	width: 0.964285093049206em;
}

.fa-line-chart {
	width: 1.1428574323654175em;
}

.fa-lastfm {
	width: 1.0000022376577817em;
}

.fa-lastfm-square {
	width: 0.8571416462459638em;
}

.fa-toggle-off {
	width: 1.142858593414303em;
}

.fa-toggle-on {
	width: 1.14285634085536em;
}

.fa-bicycle {
	width: 1.28571296234918em;
}

.fa-bus {
	width: 0.8571415692567825em;
}

.fa-ioxhost {
	width: 1.1428571175783873em;
}

.fa-angellist {
	width: 0.7142852619290352em;
}

.fa-cc {
	width: 1.1428574323654175em;
}

.fa-ils {
	width: 0.7678564703091979em;
}

.fa-meanpath {
	width: 0.8571436131994687em;
}

.fa-buysellads {
	width: 0.8571436107158661em;
}

.fa-connectdevelop {
	width: 1.1428584707900882em;
}

.fa-dashcube {
	width: 0.785714864730835em;
}

.fa-forumbee {
	width: 0.8571425694972277em;
}

.fa-leanpub {
	width: 1.142857511062175em;
}

.fa-sellsy {
	width: 1.1428565382957458em;
}

.fa-shirtsinbulk {
	width: 0.8571415543556213em;
}

.fa-simplybuilt {
	width: 1.142860271036625em;
}

.fa-skyatlas {
	width: 1.14285752673959em;
}

.fa-cart-plus {
	width: 0.928574200719595em;
}

.fa-cart-arrow-down {
	width: 0.928574200719595em;
}

.fa-diamond {
	width: 1.142861483618617em;
}

.fa-ship {
	width: 1.142856314778328em;
}

.fa-user-secret {
	width: 0.785717862347763em;
}

.fa-motorcycle {
	width: 1.2885052015384417em;
}

.fa-street-view {
	width: 0.7857207879424095em;
}

.fa-heartbeat {
	width: 1.0000009760260582em;
}

.fa-venus {
	width: 0.6395084308460355em;
}

.fa-mars {
	width: 0.8571416437625885em;
}

.fa-mercury {
	width: 0.6428571790456772em;
}

.fa-intersex {
	width: 0.7823648327030241em;
}

.fa-transgender-alt {
	width: 0.9285693382844329em;
}

.fa-venus-double {
	width: 0.9999989913776517em;
}

.fa-mars-double {
	width: 1.0690097333863378em;
}

.fa-venus-mars {
	width: 1.1376486765220761em;
}

.fa-mars-stroke {
	width: 0.8571426570415497em;
}

.fa-mars-stroke-v {
	width: 0.6395094422623515em;
}

.fa-mars-stroke-h {
	width: 1.066592177376151em;
}

.fa-neuter {
	width: 0.6428564637899399em;
}

.fa-genderless {
	width: 0.6428574621677399em;
}

.fa-facebook-official {
	width: 0.8571436237543821em;
}

.fa-pinterest-p {
	width: 0.7142868041992188em;
}

.fa-whatsapp {
	width: 0.857144296169281em;
}

.fa-user-plus {
	width: 1.1428584034244977em;
}

.fa-user-times {
	width: 1.1378349239627532em;
}

.fa-bed {
	width: 1.1428574621677399em;
}

.fa-viacoin {
	width: 0.8571425676345825em;
}

.fa-train {
	width: 0.857141618927244em;
}

.fa-subway {
	width: 0.857141618927244em;
}

.fa-medium {
	width: 1.0000000428408384em;
}

.fa-y-combinator {
	width: 0.8571425676345825em;
}

.fa-optin-monster {
	width: 1.2812500447034836em;
}

.fa-opencart {
	width: 1.2865476533770561em;
}

.fa-expeditedssl {
	width: 1.0000000049670539em;
}
.fa-battery-full {
	width: 1.2857148237526417em;
}
.fa-battery-4 {
	width: 1.2857148237526417em;
}

.fa-battery-3 {
	width: 1.285716611891985em;
}

.fa-battery-2 {
	width: 1.285716611891985em;
}

.fa-battery-1 {
	width: 1.285716611891985em;
}

.fa-battery-0 {
	width: 1.285716611891985em;
}

.fa-mouse-pointer {
	width: 0.6463916040956974em;
}

.fa-i-cursor {
	width: 0.5em;
}

.fa-object-group {
	width: 1.142856389284134em;
}

.fa-object-ungroup {
	width: 1.2857120633125305em;
}

.fa-sticky-note {
	width: 0.8571426197886467em;
}

.fa-sticky-note-o {
	width: 0.8571405932307243em;
}

.fa-cc-jcb {
	width: 1.2857130939760282em;
}

.fa-cc-diners-club {
	width: 1.2857130939760282em;
}

.fa-clone {
	width: 0.9999999590218067em;
}

.fa-balance-scale {
	width: 1.2942902165651321em;
}

.fa-hourglass-o {
	width: 0.8571416353806853em;
}

.fa-hourglass-1 {
	width: 0.8571416353806853em;
}

.fa-hourglass-2 {
	width: 0.8571416353806853em;
}

.fa-hourglass-3 {
	width: 0.8571416353806853em;
}

.fa-hourglass {
	width: 0.8571436023339629em;
}

.fa-hand-grab-o {
	width: 0.8571436107158661em;
}

.fa-hand-paper-o {
	width: 0.9107128493487835em;
}

.fa-hand-scissors-o {
	width: 0.9999980007607974em;
}

.fa-hand-lizard-o {
	width: 1.1428582593798637em;
}

.fa-hand-spock-o {
	width: 1.0714277178049088em;
}

.fa-hand-pointer-o {
	width: 0.9285682551562786em;
}

.fa-trademark {
	width: 1.1008171644061804em;
}

.fa-registered {
	width: 1.0000000049670539em;
}

.fa-creative-commons {
	width: 1.0000000049670539em;
}

.fa-gg {
	width: 1.142856478691101em;
}

.fa-gg-circle {
	width: 1.0000000049670539em;
}

.fa-tripadvisor {
	width: 1.2857139185070992em;
}

.fa-odnoklassniki {
	width: 0.7142824977636337em;
}

.fa-odnoklassniki-square {
	width: 0.8571416462459638em;
}

.fa-get-pocket {
	width: 0.959822304546833em;
}

.fa-wikipedia-w {
	width: 1.2857110323384404em;
}

.fa-safari {
	width: 1.0000000049670539em;
}

.fa-chrome {
	width: 1.0002786591649055em;
}

.fa-firefox {
	width: 0.9985101446509361em;
}

.fa-opera {
	width: 1.0000011399388313em;
}

.fa-internet-explorer {
	width: 0.9999980032444em;
}

.fa-television {
	width: 1.1714316554367542em;
}

.fa-contao {
	width: 1.000000111758709em;
}

.fa-500px {
	width: 0.7951981648802757em;
}

.fa-amazon {
	width: 1.0010244324803352em;
}

.fa-calendar-plus-o {
	width: 0.9285713012019414em;
}

.fa-calendar-minus-o {
	width: 0.9285707051554937em;
}

.fa-calendar-times-o {
	width: 0.9285707051554937em;
}

.fa-calendar-check-o {
	width: 0.9285707051554937em;
}

.fa-industry {
	width: 0.9999989531934261em;
}

.fa-map-pin {
	width: 0.5714278568824156em;
}

.fa-map-signs {
	width: 1.0000010207295418em;
}

.fa-map-o {
	width: 1.1428583338856697em;
}

.fa-map {
	width: 0.9999999906867743em;
}

.fa-commenting {
	width: 1.0000040555996748em;
}

.fa-commenting-o {
	width: 0.9999974196152834em;
}

.fa-houzz {
	width: 0.5714277625083923em;
}

.fa-vimeo {
	width: 1.0050220787525177em;
}

.fa-black-tie {
	width: 0.8571425676345825em;
}

.fa-fonticons {
	width: 0.8571425676345825em;
}

.fa-reddit-alien {
	width: 1.0000009834766388em;
}

.fa-edge {
	width: 0.9999999701976776em;
}

.fa-credit-card-alt {
	width: 1.2857139110565186em;
}

.fa-codiepie {
	width: 0.9542412211497626em;
}

.fa-modx {
	width: 0.9999999403953552em;
}

.fa-fort-awesome {
	width: 0.9285743236541748em;
}

.fa-usb {
	width: 1.2857153688868266em;
}

.fa-product-hunt {
	width: 1.0000000049670539em;
}

.fa-mixcloud {
	width: 1.2857142388820648em;
}

.fa-scribd {
	width: 0.8569198697805405em;
}

.fa-pause-circle {
	width: 0.8571425850192682em;
}

.fa-pause-circle-o {
	width: 0.8571425850192682em;
}

.fa-stop-circle {
	width: 0.8571425850192682em;
}

.fa-stop-circle-o {
	width: 0.8571425850192682em;
}

.fa-shopping-bag {
	width: 0.9999990325886756em;
}

.fa-shopping-basket {
	width: 1.1428582395116678em;
}

.fa-hashtag {
	width: 1.000002235174179em;
}

.fa-bluetooth {
	width: 0.8571405559778214em;
}

.fa-bluetooth-b {
	width: 0.5714296698570251em;
}

.fa-percent {
	width: 0.857141618927244em;
}
.fa-university {
	width: 1.1428583338856697em;
}