.slick-prev:before {
    color: black !important;
  }
  .slick-next:before {
    color: black !important;
  }
  
  /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -5px;
  }